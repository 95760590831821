<template>
  <div>
    <div class="Ui-Button">
      <a href="https://lowcarboyui.base.shop" target="_blank" rel="noopener noreferrer">
        <div class="online">
        結のオンラインショップはコチラ
        </div>
      </a>
    </div>
    <p class="text_Ui-Button">
      ミツバチ食堂の店内でも購入<br class="sp">（イートイン・テイクアウト）できます。
    </p>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiButton",
  components: {},
  props: {
    // path: String,
    // text: String,
    // type: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.Ui-Button{
display: flex;
align-items: center;
justify-content: center;
    a {
      text-decoration: none;
      color: #ffffff;
  .online {
    margin-top: 50px;
    padding: 15px 20px;
    background-color: #545454;
    font-size: 14px;
    }
  }
    &:hover{
      opacity: 0.8;
    }
      }

.text_Ui-Button {
  text-align: center;
  margin-top: 50px;
}
</style>