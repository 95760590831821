<template>
  <div class="home-map">
    <div class="wrap">
      <UiHeading
        :h="'2'"
        :type="'D2'"
        :text="`糖質量93%OFF！<br>糖質は結のチーズケーキ14.4個分でショートカットケーキ1個分`"
        style="line-height: 2.5em; margin-bottom: 50px"
      />
      <img :src="`${rootUrl}/img/yui-top-004.jpg`" alt="" class="pc" />
      <img :src="`${rootUrl}/img/yui-top-004sp.jpg`" alt="" class="sp" />
      <div class="coment">
        <p>
          ※日本食品標準成分表2020(八訂)におけるショートケーキ100g当たりの糖質量の基準値41.8gと比較して糖質93%オフ
        </p>
  
        <p>
          科学的根拠に基づいて「おいしく、楽しく食べて、健康に」を現実化していく協会である食・楽・健康協会では、ロカボの間食は糖質量10g以下が推奨されています。結では、食・楽・健康協会の推奨に基づいて商品を開発しています。結のチーズケーキは一箱すべて食べても糖質量は7.2g。1カットあたりの糖質量は2.9gです。ダイエットや健康を気にされる方でも、おいしく、お楽しみいただけます。
        </p>
      </div>
    </div>

    <div class="wrap space-150 zasshi">
      <img
        :src="`${rootUrl}/img/yui-top-20221014-pa01.png`"
        alt="赤い四つ角1"
        class="no1"
      />
      <img
        :src="`${rootUrl}/img/yui-top-20221014-pa02.png`"
        alt="赤い四つ角2"
        class="no2"
      />
      <img
        :src="`${rootUrl}/img/yui-top-20221014-pa03.png`"
        alt="赤い四つ角3"
        class="no3"
      />
      <img
        :src="`${rootUrl}/img/yui-top-20221014-pa04.png`"
        alt="赤い四つ角4"
        class="no4"
      />
      <div class="content">
        <h2>結のチーズケーキがarに掲載されました</h2>
        <div class="content-bottom">
          <img
            :src="`${rootUrl}/img/yui-top-20221014-004.jpg`"
            alt="ar"
            class="ar"
          />
          <p>
            1箱食べても糖質量7.2g。健康でヘルシーな結の低糖質チーズケーキが雑誌ar（アール）に掲載されました。
          </p>
        </div>
      </div>
    </div>

    <div class="base space-150">
      <div class="wrap space-150p">
        <div class="content">
          <ul class="item-list">
            <li class="item-box">
              <div class="item-img">
                <img :src="`${rootUrl}/img/yui_2023-9-001.jpg`" alt="" class="pc">
                <img :src="`${rootUrl}/img/yui_2023-9-001sp.jpg`" alt="" class="sp">
              </div>
              <div class="item-body">
                <div class="item-head">
                  <h2>NYチーズケーキ</h2>
                  <p>
                    人気No.1
                    <br>
                    北海道産生乳を使用したクリームチーズと
                    <br>
                    純生クリームを使用することで
                    <br>
                    濃厚な味わいになっています。
                  </p>
                </div>
                <table class="no1">
                  <tr>
                    <th>サイズ</th>
                    <th class="no2">店頭価格<br class="sp">(税込)</th>
                    <th class="no3">BASEでの価格<br class="sp">(税込)</th>
                    <th>糖質量</th>
                  </tr>
                  <tr>
                    <td>4号</td>
                    <td>4,800円</td>
                    <td>5,200円</td>
                    <td>14.5ｇ</td>
                  </tr>
                  <tr>
                    <td>5号</td>
                    <td>6,300円</td>
                    <td>7,300円</td>
                    <td>19ｇ</td>
                  </tr>
                  <tr>
                    <td>6号</td>
                    <td>8,800円</td>
                    <td>9,800円</td>
                    <td>29ｇ</td>
                  </tr>
                </table>
              </div>
            </li>
            <li class="item-box">
              <div class="item-img">
                <img :src="`${rootUrl}/img/yui_2023-9-002.jpg`" alt="" class="pc">
                <img :src="`${rootUrl}/img/yui_2023-9-002sp.jpg`" alt="" class="sp">
              </div>
              <div class="item-body">
                <div class="item-head">
                  <h2>ガトーショコラ</h2>
                  <p>
                    酸味の少ない高カカオチョコレートを使用し、<br>できるだけシンプルな材料で作り上げました。
                    <br>
                    グルテンフリーで、カカオ感たっぷりの<br>美味しいガトーショコラです。
                  </p>
                </div>
                <table class="no2">
                  <tr>
                    <th>サイズ</th>
                    <th class="no2">店頭価格<br class="sp">(税込)</th>
                    <th class="no3">BASEでの価格<br class="sp">(税込)</th>
                    <th>糖質量</th>
                  </tr>
                  <tr>
                    <td>4号</td>
                    <td>3,100円</td>
                    <td>3,800円</td>
                    <td>26.5ｇ</td>
                  </tr>
                  <tr>
                    <td>5号</td>
                    <td>4,100円</td>
                    <td>5,000円</td>
                    <td>35ｇ</td>
                  </tr>
                  <tr>
                    <td>6号</td>
                    <td>5,900円</td>
                    <td>7,100円</td>
                    <td>53ｇ</td>
                  </tr>
                </table>
              </div>
            </li>
            <li class="item-box">
              <div class="item-img">
                <img :src="`${rootUrl}/img/yui_2023-9-003.jpg`" alt="" class="pc">
                <img :src="`${rootUrl}/img/yui_2023-9-003sp.jpg`" alt="" class="sp">
              </div>
              <div class="item-body">
                <div class="item-head">
                  <h2>ショートケーキ<span>店頭販売のみ</span></h2>
                  <p>
                    おからパウダーを使用し、独自の製法でほぼ糖質0のスポンジを作り上げ、業界でも稀なグルテンフリーかつ低糖質のスポンジケーキです。<br class="sp">純生クリームを使い、甘さ控えめで口溶けの良い商品です。
                  </p>
                </div>
                <table>
                  <tr class="thead">
                    <th>サイズ</th>
                    <th class="no2">店頭価格<br class="sp">(税込)</th>
                    <th>糖質量</th>
                  </tr>
                  <tr>
                    <td>4号</td>
                    <td>4,500円</td>
                    <td>約10ｇ</td>
                  </tr>
                  <tr>
                    <td>5号</td>
                    <td>6,300円</td>
                    <td>約20ｇ</td>
                  </tr>
                  <tr>
                    <td>6号</td>
                    <td>7,800円</td>
                    <td>約33ｇ</td>
                  </tr>
                </table>
              </div>
            </li>
            <li class="item-box">
              <div class="item-img">
                <img :src="`${rootUrl}/img/yui_2023-9-004.jpg`" alt="" class="pc">
                <img :src="`${rootUrl}/img/yui_2023-9-004sp.jpg`" alt="" class="sp">
              </div>
              <div class="item-body">
                <div class="item-head">
                  <h2>オーダーケーキ</h2>
                  <p>
                    写真ケーキも可能です。
                    <br>
                    値段、デザインなどは応相談です。
                </p>
                </div>
              </div>
            </li>
            <li class="item-box">
              <div class="item-img">
                <img :src="`${rootUrl}/img/yui_2023-9-005.jpg`" alt="" class="pc">
                <img :src="`${rootUrl}/img/yui_2023-9-005sp.jpg`" alt="" class="sp">
              </div>
              <div class="item-body">
                <div class="item-head">
                  <h2>ほうじ茶ロール<span>店頭販売のみ</span></h2>
                  <p>
                    広い世代に人気のロールケーキです。
                    <br>
                    中には豆腐の生チョコレートが入っています。
                    <br>
                    生チョコのほろ苦さとほうじ茶の甘く香ばしい香りが最高のハーモニーです。
                  </p>
                </div>
                <table>
                  <tr class="thead">
                    <th>サイズ</th>
                    <th class="no2">店頭価格<br class="sp">(税込)</th>
                    <th>糖質量</th>
                  </tr>
                  <tr class="tbody">
                    <td>1本</td>
                    <td>5,300円</td>
                    <td>約10ｇ</td>
                  </tr>
                  <tr class="tbody">
                    <td>ハーフ</td>
                    <td>2,900円</td>
                    <td>約5ｇ</td>
                  </tr>
                </table>
                <div class="sub-item-box">
                  <h3 class="is-sp">デコロール<span>(フルーツのせ)</span><br>もあります</h3>
                  <div class="sub-item-content">
                    <div class="sub-item-img">
                      <img :src="`${rootUrl}/img/yui_2023-9-001.png`" alt="" class="pc">
                      <img :src="`${rootUrl}/img/yui_2023-9-001sp.png`" alt="" class="sp">
                    </div>
                    <div class="sub-item-body">
                      <h3 class="is-pc">デコロール<span>(フルーツのせ)</span>もあります</h3>
                      <p>店頭価格(税込)</p>
                      <dl>
                        <dt>1本</dt>
                        <dd>5,800円</dd>
                        <dt>ハーフ</dt>
                        <dd>3,300円</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="content-bottom">
            <div class="comment">
              <h4>ご注文に関して</h4>
              <p>
                少しでもおいしく召し上がっていただくために完全受注生産しております。<br>3営業日前のご注文をお願いいたします。<br class="sp">お電話、またはInstagramのDMにてご注文をお願いいたします。
                <br>
                ※いちごのショートケーキに関しましては夏季のみ発注の都合上5営業日前のご注文が確実です。フルーツショートケーキに関しては通常通り3営業日前で承ります。
              </p>
            </div>
            <UiButton />
          </div>
        </div>
      </div>
    </div>

    <div class="wrap space-150">
      <UiHeading
        :h="'2'"
        :type="'D2'"
        :text="'糖質を抑えるメリット'"
        style="line-height: 2.5em; margin-bottom: 50px"
      />
      <p>糖質を抑えることで血糖値が上がりにくくなります。それによって、、</p>

      <div class="outer">
        <div class="merit-box">
          <p class="title">太りにくい</p>
          <p class="text">
            血糖値が急激に上がるとインスリン(ホルモン)が過剰分泌され、糖質を脂肪に変えてしまいますが、低糖質の場合は血糖値が上がるのを抑えることができます。
          </p>
        </div>

        <div class="merit-box">
          <p class="title">眠くなりにくい</p>
          <p class="text">
            血糖値が上がると人は眠くなります。血糖値が上がりづらいので眠くならず、仕事の能率がUPします。
          </p>
        </div>

        <div class="merit-box">
          <p class="title">
            ダイエット・<br class="pc-kaigyo" />美容・<br
              class="sp-kaigyo"
            />アンチエイジングに
          </p>
          <p class="text">
            AGE(終末糖化産物)が生産されにくいため、悪玉コレステロールの増加や肌の糖化が起こりにくいです。
          </p>
        </div>
      </div>
      <div class="butto">
        <UiButton />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
import UiButton from "@/components/Ui/Button.vue";

export default {
  name: "HomeCarbohydrateOff",
  components: {
    UiHeading,
    UiButton,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-map {
  .pc {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .sp {
    display: none;
    @media screen and (max-width: 768px) {
      display: initial;
      width: 100%;
    }
  }
  .coment {
    margin-top: 50px;
    p {
      margin-bottom: 30px;
    }
  }
  .zasshi {
    position: relative;
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 767px) {
      padding: 50px;
    }
    .no1,
    .no2,
    .no3,
    .no4 {
      position: absolute;
      width: 7%;
      @media screen and (max-width: 767px) {
        width: 15%;
      }
    }
    .no1 {
      top: 0;
      left: 0;
    }
    .no2 {
      top: 0;
      right: 0;
    }
    .no3 {
      bottom: 0;
      right: 0;
    }
    .no4 {
      bottom: 0;
      left: 0;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 70%;
      @media screen and (max-width: 767px) {
        width: 95%;
      }
      h2 {
        color: var(--main);
        padding-bottom: 50px;
      }
      .content-bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 90%;

        @media screen and (max-width: 767px) {
          flex-direction: column;
          align-items: center;
          width: 100%;
        }

        .ar {
          width: 50%;
          @media screen and (max-width: 767px) {
            width: 80%;
          }
        }
        p {
          width: 45%;
          line-height: 2em;
          @media screen and (max-width: 767px) {
            width: 100%;
            line-height: 1.5em;
            margin-top: 30px;
          }
        }
      }
    }
  }
}

.base {
  background-color: rgb(253, 239, 235);
  padding-bottom: 150px;
  @media screen and (max-width: 767px){
    padding-bottom: 75px; 
  }
  .head{
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      justify-content: space-around;
    }

    @media screen and (max-width: 767px) {
      align-items: flex-start;
    }

    h2 {
      color: var(--main);
      font-size: 32px;

      @media screen and (max-width: 767px) {
        font-size: 28px;
        line-height: 2em;
      }
    }
    
    .head-img {
      @media screen and (max-width: 767px) {
        width: 200px;
        margin-top: 8px;
      }
      img {
        height: 80px;
        object-fit: cover;

        @media screen and (max-width: 767px) {
        height: auto;
      }
      }
    }
  }
  .content {
    margin-top: 80px;

    @media screen and (max-width: 767px) {
      margin-top: 20px;
      }

    ul {
      border-top: 1px var(--main) solid;

      li {
        display: flex;
        justify-content: space-between;
        padding: 80px 0;
        border-bottom: 1px var(--main) solid;

        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          align-items: flex-end;
        }
        
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          display: block;

          &:last-child {
            .item-body {
              flex-wrap: wrap;
            }
          }
        }

        @media screen and (max-width: 767px){
          display: block;
          padding: 40px 0;
        }
        .item-img {
          width: 55%;

          @media screen and (max-width: 767px){
            width: 100%;
          }

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
            vertical-align: middle;
          }
        }
        .item-body {
          width: 40%;
          margin-top: 20px;

          .no1, .no2 {
              width: 100%;

              @media screen and (min-width: 768px) and (max-width: 1023px) {
                  width: 50%;
                }
            }

          @media screen and (min-width: 768px) and (max-width: 1023px) {
            display: flex;
            width: 100%;
          }
          
          @media screen and (max-width: 767px){
            width: 100%;
          }
          
          .item-head {
            @media screen and (min-width: 768px) and (max-width: 1023px) {
              width: 48%;
            }
            
            h2 {
              font-size: 28px;
              color: var(--main);
              
              span {
                background: #fff;
                color: #545454;
                font-size: 18px;
                padding: 0.5em;
                margin-left: 40px;
                vertical-align: bottom;
                
                @media screen and (min-width: 768px) and (max-width: 1023px) {
                  margin-left: 24px;
                }
                @media screen and (max-width: 767px) {
                  font-size: 16px;
                  margin-left: 24px;
                }
              }
            }
  
            p {
              margin-top: 16px;
              font-size: 18px;
              line-height: 2em;

              @media screen and (min-width: 768px) and (max-width: 1023px) {
                  font-size: 16px;
                }
  
              @media screen and (max-width: 767px){
              font-size: 15px;
              margin-top: 8px;
            }
          }
        }

          table {
            width: 80%;
            margin-top: 24px;
            border: 1px var(--main) solid;

            @media screen and (min-width: 768px) and (max-width: 1023px)  {
              margin-left: auto;
              width: 50%;
            }

            @media screen and (max-width: 767px) {
              width: 100%;
            }

            tr {
              &:nth-child(3) {
                background: #fff;
              }
              .no2 {
                width: 100px;
                @media screen and (min-width: 768px) and (max-width: 1023px) {
                  width: 100px;
                }

                @media screen and (max-width: 767px) {
                  width: 95px;
                }
              }
              .no3 {
                width: 140px;
                @media screen and (min-width: 768px) and (max-width: 1023px) {
                  width: 130px;
                }
                @media screen and (max-width: 767px) {
                  width: 120px;
                }
              }

              th {
                background: var(--main);
                color: #fff;
                padding: 0.5em;
                text-align: center;
                vertical-align: middle;

                @media screen and (max-width: 767px) {
                  vertical-align: middle;
                  padding: 0.5em 0.25em;
                }
                
                &:not(:first-child) {
                  border-left: 1px #fff solid;
                }
              }

              td {
                padding: 0.5em;
                border: 1px var(--main) solid;
                text-align: center;
  
                &:last-child {
                  text-align: right;
                }
              }
            }
            .thead{
              .no2 {
                width: 180px;
                @media screen and (min-width: 768px) and (max-width: 1023px) {
                  width: 160px;
                }
                @media screen and (max-width: 767px) {
                  width: 140px;
                }
              }
            }          
          }
        }
      }
    }
    .sub-item-box {
      margin-top: 24px;
      .is-pc {
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      .is-sp {
        display: none;
        @media screen and (max-width: 767px) {
          display: block;
        }
      }

      h3 {
        font-size: 20px;
        color: var(--main);
        margin-bottom: 8px;

        span {
          font-size: 14px;

          @media screen and (min-width: 768px) and (max-width: 1023px) {
            margin-right: 120px;
          }
          @media screen and (max-width: 767px) {
            vertical-align: bottom;
          }
        }
      }
      .sub-item-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (min-width: 768px) and (max-width: 1023px) {
          align-items: flex-end;
          }
        @media screen and (max-width: 767px) {
          align-items: flex-end;
          }

  
        .sub-item-img {
          width: 50%;

          @media screen and (max-width: 767px) {
            width: 80%;
          }
        }
  
        .sub-item-body {
          width: 45%;
  
          p {
            display: inline-block;
            font-size: 16px;
            color: var(--main);
            padding-bottom: 4px;
            border-bottom: 1px var(--main) solid;
            width: 120px;
          }
  
          dl {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 8px;
  
            dt {
              width: 35%;

              @media screen and (min-width: 768px) and (max-width: 1023px) {
                width: 20%;
              }
              @media screen and (max-width: 767px) {
                width: 50%;
              }
            }
            dd {
              width: 65%;

              @media screen and (min-width: 768px) and (max-width: 1023px) {
                width: 80%;
              }
              @media screen and (max-width: 767px) {
                width: 50%;
                text-align: center;
              }
            }
          }
        }
      }
    }

    .content-bottom {
      margin-top: 40px;
      .comment {
        display: flex;
        align-items: center;

        @media screen and (min-width: 768px) and (max-width: 1023px) {
          display: block;
        }
        @media screen and (max-width: 767px) {
          display: block;
        }

        h4 {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 20px;
          background: var(--main);
          color: #fff;
          width: 140px;
          height: 120px;
          padding: 8px;
          line-height: 1.5em;

          @media screen and (min-width: 768px) and (max-width: 1023px) {
            width: 100%;
            height: 80px;
            margin: 0 auto;
          }

          @media screen and (max-width: 767px) {
            width: 100%;
            height: 64px;
            margin: 0 auto;
          }
        }
        
        p {
          line-height: 2em;
          margin-left: 40px;

          @media screen and (min-width: 768px) and (max-width: 1023px) {
            margin-top: 24px;
            margin-left: 24px;
          }
          
          @media screen and (max-width: 767px) {
            margin-top: 24px;
            margin-left: 0;
          }
        }
      }
      .button {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          text-decoration: none;
          color: #ffffff;
          .online {
            margin-top: 50px;
            padding: 20px;
            background-color: #545454;
            font-size: 25px;

            @media screen and (max-width: 767px) {
              font-size: 20px;
            }
          }
        }
        &:hover{
          opacity: 0.8;
        }
      }
    }
  }
}
.outer {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  .merit-box {
    min-height: 250px;
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    // padding: 40px 20px;
    border: 2px var(--main) solid;
    margin: 30px 0;
    padding: 0 20px;
    // @media screen and (min-width: 768px) and (max-width: 1024px) {
    //   height: auto;
    // }
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .title {
      // vertical-align: bottom;
      color: var(--main);
      font-size: 25px;
      height: 20%;
      font-weight: bold;
      margin-bottom: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1.5em;
      @media screen and (max-width: 767px) {
        line-height: 1.2em;
      }
    }
    .text {
      height: 50%;
      line-height: 2em;
          @media screen and (min-width:769px) and ( max-width:1024px){
      min-height: 250px;
    }
    }
  }
  .merit-box:last-child {
    @media screen and (max-width: 767px) {
      .title {
        height: 100px;
      }
    }
  }
}
</style>
