import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'





import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '低糖質専門店 結',
      description: '低糖質専門店の結では、「食べることを我慢しない」をテーマに、低糖質のスイーツやパンを1品ずつ手作りしています。ダイエット、糖尿病や癌などで糖質制限をされている方も我慢することなく、おいしく、楽しく召し上がっていただけます。オンラインショップとミツバチ食堂の店内（イートイン・テイクアウト）で販売しています。'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

  next();
});

export default router
