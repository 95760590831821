<template>
  <div class="home-libre">
    <div class="wrap space-150p">
      <div class="red-title">
        <div class="inner">
          <img
            :src="`${rootUrl}/img/yui-top-20221014-pa05.png`"
            alt="かっこ左"
          />
          <h2>リブレで見る<br class="sp" />結の低糖質の力</h2>
          <img
            :src="`${rootUrl}/img/yui-top-20221014-pa06.png`"
            alt="かっこ右"
          />
        </div>
      </div>

      <p class="text">
        リブレとは、いつでもどこでもグルコース値の測定を可能にする、糖尿病患者さん向けグルコースモニタリングシステムです。結の低糖質チーズケーキやランチを食べられた1型糖尿病の方に食べた結果のデータを以下に掲載します。結のケーキ・ランチは、血糖値の急な上昇を抑え、インスリンの量も減らせることがわかります。
      </p>

      <h3>
        1型糖尿病患者が<span class="pen">インスリンを打たずに</span><br class="pc" />
        結の低糖質ケーキとランチを食べた血糖値の変化
      </h3>

      <div class="white-back">
        <p class="sub-title">
          ◎低糖質チーズケーキ(100g)＋コーヒーの血糖値の変化
        </p>
        <div class="white-back-top">
          <div class="left">
            <img
              :src="`${rootUrl}/img/yui-top-20221014-005.jpg`"
              alt="店内"
              class="pc"
            />
            <img
              :src="`${rootUrl}/img/yui-top-20221014-005sp.jpg`"
              alt="店内"
              class="sp"
            />
            <p>カットケーキ<br /><span>（1カット約100g・糖質量2.9g）</span></p>
          </div>
          <div class="right">
            <img
              :src="`${rootUrl}/img/yui-top-20221014-009.jpg`"
              alt="通販"
              class="pc"
            />
            <img
              :src="`${rootUrl}/img/yui-top-20221014-009sp.jpg`"
              alt="通販"
              class="sp"
            />
            <p>
              ホールケーキ<br /><span
                >（1カット約100g・糖質量2.9g）<br /></span><span style="font-size: 14px"
                >※写真は1箱250g・糖質量7.2g</span
              >
            </p>
          </div>
        </div>
        <img
          :src="`${rootUrl}/img/yui-top-20221014-hyou01.jpg`"
          alt="グラフ１"
          class="glaf"
        />
        <p class="coment">
          低糖質チーズケーキとコーヒーを摂取してから2時間、血糖値が上昇せず、最大値が128!
        </p>
        <img
          :src="`${rootUrl}/img/yui-top-20221014-hyou02.jpg`"
          alt="グラフ2"
          class="glaf"
        />
        <p class="coment" style="margin-top:14px;">
          インスリンの量も減り、低糖質チーズケーキと摂取してから2時間、血糖値が上昇せず、最大値が92!
        </p>
      </div>
      <div class="white-back">
        <p class="sub-title">◎低糖質ロールケーキ(約70g)の血糖値の変化</p>
        <div class="white-back-top center">
          <div class="left">
            <img
              :src="`${rootUrl}/img/yui-top-20221014-007.jpg`"
              alt="店内"
              class="pc"
            />
            <img
              :src="`${rootUrl}/img/yui-top-20221014-007sp.jpg`"
              alt="店内"
              class="sp"
            />
            <p class="center-text">
              ロールケーキ<br /><span>（1カット約70g・糖質量1.5g）</span><br />
              <span style="font-size: 14px"
                >※通販のロールケーキは販売準備中</span
              >
            </p>
          </div>
        </div>
        <img
          :src="`${rootUrl}/img/yui-top-20221014-hyou03.jpg`"
          alt="グラフ３"
          class="glaf"
        />
        <p class="coment">
          低糖質ロールケーキを摂取してから3時間、血糖値が上昇せず、最大値が112!
        </p>
      </div>

      <UiButton />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiButton from "@/components/Ui/Button.vue";
export default {
  name: "FlAboutFl",
  components: {
    UiButton,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-libre {
  background-color: rgb(253, 239, 235);
  padding-bottom: 150px;
  @media screen and (max-width: 767px){
         padding-bottom: 75px; 
        }
  .red-title {
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 60%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    h2 {
      font-size: 32px;
      text-align: center;
      padding-bottom: 10px;
      color: var(--main);
      @media screen and (max-width: 767px) {
        font-size: 28px;
        padding: 0 10px;
        line-height: 1.5em;
      }

    }
    img {
      width: 18px;
    }
  }
  .text {
    margin: 50px 0;
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }
  }
  h3 {
    font-size: 26px;
    color: var(--main);
    line-height: 1.5em;
      .pen{
      display: inline;
      background: linear-gradient(transparent 60%, #e8bcbb 60%);
      // font-size: 26px;
      // text-align: left;
      }
    @media screen and (max-width: 767px) {
      font-size: 22px;
    }
  }
  .white-back {
    background-color: white;
    padding: 50px 50px 50px;
    margin: 30px 0;
    @media screen and (max-width: 767px) {
      padding: 30px 10px;
      margin-bottom: 0;
    }
    .sub-title {
      color: var(--main);
      font-size: 24px;
      @media screen and (max-width: 767px){
         font-size: 20px; 
        }
    }
    .white-back-top {
      padding: 20px;
      margin-bottom: 50px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px){
         padding: 20px 0; 
         margin-bottom: 30px;
        }

      .right,
      .left {
        width: 48%;
        p {
          font-weight: bold;
          span {
            font-weight: normal;
            @media screen and (max-width: 767px) {
              font-size: 14px;
              letter-spacing: -0.1em;
            }
          }
        }
      }
    }
    .center {
      justify-content: center;
      .left {
        width: 50%;
      }
    }
    .graf {
      width: 100%;
      margin-top: 50px;
    }
    .coment {
      font-size: 22px;
      color: var(--main);
      margin-bottom: 80px;
      @media screen and (max-width: 767px){
         font-size: 18px; 
         margin-bottom: 50px;
        }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
