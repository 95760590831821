var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-item-box"},[_c('div',{class:{
      typeA: _vm.type === 'A',
      //左：table
      //右：img
      typeB: _vm.type === 'B',
      //右：
      //右：img
    }},[_c('div',{staticClass:"item-cover"},[_c('img',{staticClass:"photo",attrs:{"src":`${_vm.rootUrl}/img/${_vm.imgpath}`,"alt":{ imgName: _vm.imgName }}}),(_vm.type === 'A')?_c('div',{staticClass:"right-cover"},[_c('div',{staticClass:"shop-info"},[_c('table',[_c('tr',[_vm._m(0),_c('td',{domProps:{"innerHTML":_vm._s(_vm.itemName)}})]),_c('tr',[_vm._m(1),_c('td',{domProps:{"innerHTML":_vm._s(_vm.price)}})]),_c('tr',[_vm._m(2),_c('td',{domProps:{"innerHTML":_vm._s(_vm.delivery)}})])])]),_c('div',{staticClass:"text-box"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.itemText)}})])]):(_vm.type === 'B')?_c('div',{staticClass:"right-cover"},[_c('div',{staticClass:"shop-info"},[_c('p',{staticClass:"name",domProps:{"innerHTML":_vm._s(_vm.itemName)}}),_c('p',{staticClass:"price",domProps:{"innerHTML":_vm._s(_vm.price)}}),_c('p',{staticClass:"kome",domProps:{"innerHTML":_vm._s(_vm.itemText)}}),_c('p',{staticClass:"coment",domProps:{"innerHTML":_vm._s(_vm.itemComent)}})])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("商品名")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("価格")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("送料と配送")])])
}]

export { render, staticRenderFns }