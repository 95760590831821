var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-box"},[_c('div',{class:{
      typeA: _vm.type === 'A',
      //1列：img
      //2列：text
      
      typeB: _vm.type === 'B',
      //1列(左：img 右：text)
      typeB2: _vm.type === 'B2',
      typeC: _vm.type === '',
      //1列(右：img 左：text)
    }},[_c('div',{staticClass:"cover"},[_c('img',{staticClass:"photo",attrs:{"src":`${_vm.rootUrl}/img/${_vm.imgpath}`,"alt":{ imgName: _vm.imgName }}}),_c('div',{staticClass:"text-area"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.text)}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }