<template>
  <div class="home">
    <img :src="`${rootUrl}/img/header.jpg`" alt="結トップ画像" class="pc" />
    <img :src="`${rootUrl}/img/header-sp.jpg`" alt="結トップ画像" class="sp" />
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <div class="wrap">
      <div class="space-200" id="about">
        <HomeAbout />
      </div>
    </div>

    <div class="space-200" id="mission">
      <div
        class="back"
        :style="`background-image: url(${rootUrl}/img/yui-mission-back.jpg);`"
      >
        <div class="wrap">
          <HomeMission />
        </div>
      </div>
    </div>
    <div class="space-200" id="commitment">
      
        <HomeCommitment />

    </div>

    <div class="space-200">
        <HomeCarbohydrateOff />
    </div>

    <div class="space-100">
        <HomeLibre />
    </div>

    <div class="space-200" id="cafemenu">
      <div class="wrap">
        <HomeCafeMenu />
      </div>
    </div>

    <div class="space-200" id="info">
      <div class="wrap">
        <HomeMap />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeAbout from "@/components/Home/About.vue";
import HomeMap from "@/components/Home/Map.vue";
import HomeMission from "@/components/Home/Mission.vue";
import HomeCommitment from "@/components/Home/Commitment.vue";
import HomeCarbohydrateOff from "@/components/Home/CarbohydrateOff.vue";
import HomeLibre from "@/components/Home/Libre.vue";
import HomeCafeMenu from "@/components/Home/CafeMenu.vue";

export default {
  name: "Home",
  components: {
    HomeAbout,
    HomeMap,
    HomeMission,
    HomeCommitment,
    HomeCarbohydrateOff,
    HomeCafeMenu,
    HomeLibre,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.back {
  padding: 80px 0;
  // background-attachment: ;
  background-size: cover;
  // background-position: center;
  background-repeat: no-repeat;
}

.note {
  width: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  // background-color: #cccccc;
  // background-color: #f5bbb3;
  // background-color: #fcddd9;
  border:2px solid var(--main);
  padding: 15px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
  h4 {
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
  }
  p{
    width: 92%;

  }
}
  .online-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
