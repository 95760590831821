<template>
  <div class="home-mission">
    <div class="wrap">
      <UiHeading
        :h="'2'"
        :type="'D'"
        :text="'こだわりのチーズケーキ'"
        :subTitle="'Cheese-cake'"
      />
      <UiItemBox
        :type="`A`"
        :imgpath="`yui-top-20221014-001.jpg`"
        :imgName="`低糖質NYチーズケーキ`"
        :itemName="`低糖質NYチーズケーキ`"
        :price="`1ホール 3,300円(税込)`"
        :delivery="`ヤマト便クール便：1,050～1,700円（税込）<br>※ケーキ6ホールまで同額で配送`"
        :itemText="`素材と調理法にこだわり、一箱全て食べても糖質量7.2g以下。<br>低糖質と感じさせない美味しさ。小麦粉不使用でおからパウダーとアーモンドパウダーで作られたクッキー生地があることで満足感もあります。間食にも食後のデザートにも安心して食べられる糖質量です。ご自身へのご褒美や大切な方への贈り物にぜひご利用くださいませ。※賞味期限は冷凍で2ヶ月です。`"
      />
      <div class="detail">
        <h5><span>原材料</span></h5>
        <p>
          クリームチーズ、生クリーム、サワークリーム、卵、エリスリトール、バター、アーモンド、おからパウダー、レモン果汁／甘味料(ラカンカ抽出物)、バニラオイル、増粘剤(キサンタンガム)
        </p>
        <h5><span>アレルゲン</span></h5>
        <p>卵、乳、大豆、アーモンド</p>

        <h5 style="margin: 30px 0 10px">
          <span>栄養成分（1本250gあたり）</span>
        </h5>
        <p>
          エネルギー1062kcal、たんぱく質19.25g、脂質41.9g、炭水化物39g、糖質35.25g、食物繊維3.75g、食塩相当量1.25g
          ※エリスリトール、ラカンカを含まない糖質量7.2g
        </p>
      </div>
    </div>
    <div class="smooth">
      <div class="wrap rel">
        <img
          :src="`${rootUrl}/img/yui-top-20221014-002.png`"
          alt="カーネーション背景PC用"
          class="pc carnation"
        />
        <img
          :src="`${rootUrl}/img/yui-top-20221014-002sp.png`"
          alt="カーネーション背景SP用"
          class="sp carnation"
        />
        <p>
          低糖質なのに<br />
          濃厚で<br />
          なめらかで<br />
          おいしい
        </p>
        <img
          :src="`${rootUrl}/img/yui-top-20221014-002.jpg`"
          alt="低糖質なのに濃厚でなめらかでおいしいチーズケーキ"
          class="cake"
        />
      </div>
    </div>
    <div class="online-info">
      <UiButton />
    </div>
    <div class="wrap mission">
      <img
        :src="`${rootUrl}/img/yui-top-20221014-001.png`"
        alt="ピンクと赤のライン"
        class="line pc"
      />
      <img
        :src="`${rootUrl}/img/yui-top-20221014-001sp.png`"
        alt="ピンクと赤のライン"
        class="line sp"
      />
      <h3>通販用の<br class="sp" />低糖質チーズケーキに込めた思い</h3>
      <div class="text">
        <p>
          自分に贈る、大切な人へ贈る。そんなチーズケーキでありたい。だから、味と低糖質だけでなく、箱の大きさ、デザインにもこだわりました。届いて手にとったときに、うれしい気持ちになっていただきたいです。
        </p>
        <p>
          結のロゴはスイートピーとリボンのあしらいがあります。スイートピーの花言葉は、「優しい思い出」「至福の喜び」「門出」です。
        </p>
        <p>
          あなたから、結のチーズケーキが大切な人の手元に届いたこと、一緒に食べたこと、こんな優しい思い出となりたい。
        </p>
        <p>
          ダイエットや健康を気にした「我慢の選択」ではなく、「食べるものの選択」をしてほしい。低糖質にこだわった結のチーズケーキだからこそ、自分へのごほうびに。毎日のごほうびに。たくさん食べても大丈夫という安心感と至福の喜びがあります。
        </p>
        <p>
          人生の門出のプレゼント、贈り物に結のチーズケーキを。低糖質で健康だからこそ贈りやすい。もらってうれしい、食べてうれしい。人生の大切なひとときのお役に立ちたい。
        </p>
        <p>
          ロゴのリボンは結び目です。人とのご縁、人とのつながりを表します、結のチーズケーキを通して、人とのご縁やつながりが生まれ、深くなりますように。そんな思いが込められています。
        </p>
        <p>
          1箱ぜんぶ食べても大丈夫！糖質10g以下にこだわった製法と大きさの結のチーズケーキには、このような思いが込め、1品ずつ手作りて丁寧に作っています。
        </p>
      </div>

      <img
        :src="`${rootUrl}/img/yui-top-20221014-001.png`"
        alt="ピンクと赤のライン"
        class="line pc"
      />
      <img
        :src="`${rootUrl}/img/yui-top-20221014-001sp.png`"
        alt="ピンクと赤のライン"
        class="line sp"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiItemBox from "@/components/Ui/ItemBox.vue";
import UiButton from "@/components/Ui/Button.vue";

export default {
  name: "HomeMission",
  components: {
    UiHeading,
    UiItemBox,
    UiButton,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
h3 {
  padding-top: 80px;
  padding-bottom: 50px;
}
.mission-text {
  p {
    padding: 15px 0;
  }
}
.detail {
  padding: 20px;
  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }
  h5 {
    margin-bottom: 10px;
    span {
      padding-bottom: 2px;
      border-bottom: 1px solid var(--sub);
    }
  }
  h5:nth-of-type(2) {
    margin-top: 30px;
  }
}
.online-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 0;
  }
}
.smooth {
  background-color: rgb(253, 239, 235);
  margin-top: 200px;
  @media screen and (max-width: 767px) {
    height: 500px;
  }
  .rel {
    position: relative;
    padding: 50px 0;
    @media screen and (max-width: 767px) {
      padding-top: 0;
    }
    p {
      color: var(--main);
      font-size: 26px;
      line-height: 2.3em;
      letter-spacing: 0.1em;
      position: absolute;
      top: 70%;
      left: 0%;
      transform: translateY(-70%);
      -webkit-transform: translateY(-70%);
      -ms-transform: translateY(-70%);
      @media screen and (max-width: 767px) {
        width: 50%;
        height: 230px;
        writing-mode: vertical-rl;
        line-height: 1.8em;
      }
    }
    .carnation {
      width: 38%;
      @media screen and (max-width: 767px) {
        width: 50%;
        margin-left: 50%;
        margin-top: 50px;
      }
    }

    .cake {
      position: absolute;
      width: 78%;
      top: 50%;
      right: 0%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      @media screen and (max-width: 767px) {
        width: 90%;
        top: 80%;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
      }
    }
  }
}
.mission {
  padding: 100px 0 0;

  h3 {
    font-size: 32px;
    color: var(--main);
    padding: 100px 50px 50px;
    @media screen and (max-width: 767px) {
      line-height: 1.5em;
      padding: 50px 20px 30px;
    }
  }

  .text {
    padding: 0 50px;
    @media screen and (max-width: 767px) {
      padding: 0 20px;
    }
    p {
      margin-bottom: 20px;
      @media screen and (max-width: 767px) {
        margin-bottom: 30px;
        line-height: 1.8em;
      }
      &:last-child {
        margin-bottom: 100px;
        @media screen and (max-width: 767px) {
          margin-bottom: 50px;
        }
      }
    }
  }
}
.pc {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.sp {
  display: none;
  @media screen and (max-width: 768px) {
    display: initial;
  }
}
</style>
