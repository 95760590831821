<template>
  <div class="ui-map">
    <div
      :class="{
        typeA: type === 'A',
        //左：table
        //右：img
        typeB: type === 'B',
      }"
    >
      <div class="cover">
        <div class="shop-info">
          <table>
            <tr>
              <th><span>店舗名</span></th>
              <td v-html="name"></td>
            </tr>
            <tr>
              <th><span>住所</span></th>
              <td v-html="address"></td>
            </tr>
            <tr>
              <th><span>電話番号</span></th>
              <td><a href="tel:058-266-5004"><span v-html="tel"></span></a></td>
            </tr>
            <tr>
              <th><span>営業時間</span></th>
              <td v-html="open"></td>
            </tr>
            <tr>
              <th><span>定休日</span></th>
              <td v-html="close"></td>
            </tr>
            <tr>
              <th><span>instagram</span></th>
              <td>
                <a v-bind:href="insta" target="_blank" rel="noopener noreferrer"><span v-html="insta"></span></a>
              </td>
            </tr>
          </table>
        </div>
        <img
          :src="`${rootUrl}/img/${imgpath}`"
          :alt="{ imgName }"
          class="photo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiMap",
  components: {},
  props: {
    type: String,
    imgpath: String,
    imgName: String,
    name: String,
    address: String,
    tel: String,
    open: String,
    close: String,
  },
  data() {
    return {
      insta: "https://www.instagram.com/mitsubachi_chi/"
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.ui-map {
  .typeA {
    padding-top: 36px;
    .cover {
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 767px) {
        display: block;
      }

      .shop-info {
        width: 45%;
        // margin-left:auto;
        // margin-right:auto;
        table {
          width: 100%;
          a {
            text-decoration: none;
            color: #606060;
            cursor: pointer;
            word-break: break-all;
          }
          @media (min-width: 767px) {
            a[href*="tel:"] {
              cursor: text;
            }
            a[href*="tel:"]:active {
              pointer-events: none;
            }
          }
          tr {
            th,
            td {
              padding: 25px 5px;
            }
            th {
              span {
                padding-bottom: 2px;
                border-bottom: 1px solid var(--sub);
              }
              @media screen and (max-width: 767px) {
                width: 100px;
              }
            }
          }
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      .photo {
        width: 30%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
}
</style>