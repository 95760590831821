<template>
  <div class="layout-header">
    <div class="header">
      <div class="logo" @click="top">
        <img :src="`${rootUrl}/img/yui-top-001.png`" alt="ロゴ" />
        <!-- <p>低糖質専門店 結</p> -->
      </div>

      <div class="header-right">
        <div class="tel">
          <img :src="`${rootUrl}/img/yui-top-pa001.png`" alt="tel-logo" />
          <span>058-266-5004</span>
        </div>
        <a
          href="https://lowcarboyui.base.shop"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="online">オンラインショップ</div>
        </a>

        <div class="hamburger">
          <UiDrawer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiDrawer from "@/components/Ui/Drawer.vue";
export default {
  name: "LayoutHeader",
  components: {
    UiDrawer,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {
    top() {
      if (this.$route.path !== "/") {
        //遷移する
        this.$router.push("/");
      } else {
        this.$scrollTo("#app", 500, { easing: "ease" });
        // alert('トップページ');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100vw;
  display: flex;
  align-items: center;
  height: 70px;
  position: fixed;
  z-index: 999;
  // background-color: white;

  .logo {
    margin-right: auto;
    // font-size: 30px;
    margin-left: 10px;
    cursor: pointer;
    margin-right: auto;
    margin-left: 10px;
    height: 90px;
    width: 320px;
    img {
      object-fit: cover;
    }
    cursor: pointer;
    @media screen and (max-width: 767px) {
      width: 280px;
      margin-left: 0;
      height: 70px;
    }
  }

  .header-right {
    width: 470px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      width: 30%;
      justify-content: flex-end;
      // padding-right: 10px;
    }

    a {
      text-decoration: none;
      color: #ffffff;
      .online {
        padding: 15px 20px;
        // margin-right: 80px;
        background-color: #545454;
        font-size: 14px;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    &:hover {
      opacity: 0.8;
    }

    .tel {
      font-size: 21px;
      font-weight: bold;

      img {
        width: 25px;
        margin-right: 10px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
        // letter-spacing: 0.1em;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .hamburger {
      margin-right: 30px;

      @media screen and (max-width: 767px) {
        margin-right: 5px;
      }
    }
  }
}
</style>
