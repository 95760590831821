<template>
  <div class="fl-about-fl">
    <UiHeading
      :h="'1'"
      :type="'D'"
      :text="`低糖質専門店<br class='sp-kaigyo'> 結について`"
      :subTitle="'About YUI'"
    />

    <div class="slider">
      <carousel
        :autoplay="true"
        :loop="true"
        :per-page="1"
        :navigation-enabled="true"
        :pagination-padding="0"
        :speed="1000"
        pagination-color="#cccccc"
        pagination-active-color="#c1272d"
      >
        <!-- <slide> <img :src="`${rootUrl}/img/slider-1.jpeg`" alt="" class="slide-img"/> </slide> -->
        <slide>
          <img :src="`${rootUrl}/img/slider-2.jpeg`" alt="" class="slide-img" />
        </slide>
        <slide>
          <img :src="`${rootUrl}/img/slider-3.jpeg`" alt="" class="slide-img" />
        </slide>

        <slide>
          <img :src="`${rootUrl}/img/slider-5.jpeg`" alt="" class="slide-img" />
        </slide>
      </carousel>
    </div>

    <div class="text">
      <p>
        低糖質専門店 結は、「食べることを我慢しない」をテーマに、低糖質のスイーツやパンを1品ずつ手作りしています。ダイエット、糖尿病や癌などで糖質制限をされている方も我慢することなく、おいしく、楽しく召し上がっていただけます。食・楽・健康協会における「おいしく楽しく適正糖質」のロカボは、1日1食の糖質量が20～40g、間食は10gまでが適正糖質とされています。結では、この定義にそって商品を開発しています。
      </p>
    </div>
    <UiButton />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
import { Carousel, Slide } from "vue-carousel";
import UiButton from "@/components/Ui/Button.vue";

export default {
  name: "FlAboutFl",
  components: {
    UiHeading,
    Carousel,
    Slide,
    UiButton
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.slider {
  margin: 50px auto;
  width: 80%;
  @media screen and (max-width: 767px) {
    width: 95%;
  }
}
.text {
  margin: 50px 0;
}
/deep/.VueCarousel-dot-container {
  width: 150px;
  display: flex !important;
  justify-content: space-between;
  padding-top: 20px !important;
  @media screen and (max-width: 767px) {
    width: 100px;
  }
}
</style>
