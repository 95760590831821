var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heading"},[_c('div',{class:{
      typeA: _vm.type === 'A',
      typeB: _vm.type === 'B',
      typeB2: _vm.type === 'B2',
      typeB3: _vm.type === 'B3',
      typeC: _vm.type === 'C',
      typeD: _vm.type === 'D',
      typeD2: _vm.type === 'D2',
    }},[(_vm.h === '1')?_c('h1',{domProps:{"innerHTML":_vm._s(_vm.text)}}):(_vm.h === '2')?_c('h2',{domProps:{"innerHTML":_vm._s(_vm.text)}}):(_vm.h === '3')?_c('h3',{domProps:{"innerHTML":_vm._s(_vm.text)}}):(_vm.h === '4')?_c('h4',{domProps:{"innerHTML":_vm._s(_vm.text)}}):(_vm.h === '5')?_c('h5',{domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e()]),(_vm.subTitle !=='')?_c('div',{staticClass:"sub"},[_c('p',[_vm._v(" "+_vm._s(_vm.subTitle))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }