<template>
  <div class="ui-box">
    <div
      :class="{
        typeA: type === 'A',
        //1列：img
        //2列：text
        
        typeB: type === 'B',
        //1列(左：img 右：text)
        typeB2: type === 'B2',
        typeC: type === '',
        //1列(右：img 左：text)
      }"
    >
      <div class="cover">
        <img
          :src="`${rootUrl}/img/${imgpath}`"
          :alt="{ imgName }"
          class="photo"
        />
        <div class="text-area">
          <p v-html="text"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiBox",
  components: {},
  props: {
    imgpath: String,
    imgName: String,
    text: String,
    type: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.ui-box {
  .typeA {
    padding: 30px 0;
    .cover {
      display: flex;
      flex-direction: column;
      .photo {
        width: 100%;
      }
      .text-area {
        padding: 30px 0;

        p {
          word-wrap: break-word;
        }
      }
    }
  }
  .typeB,.typeC {
    padding-top: 36px;
    .cover {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .photo {
        width: 48%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      .text-area {
        width: 48%;
        padding-top: 0;
        @media screen and (max-width: 767px) {
          width: 100%;
        }

        p {
          word-wrap: break-word;
        }
      }
    }
  }
  .typeC{
    flex-direction: row-reverse;
  }
  .typeB2 {
    padding-top: 36px;
    .cover {
      padding: 30px;
      display: flex;
      // flex-direction: row-reverse;
      justify-content: space-around;
      align-items: flex-start;
      align-items: center;
      border: var(--sub) 8px solid;
      width: 80%;
      margin: 0 auto;
       @media screen and (max-width: 767px){
         display: block;
         width: 90%;
       }
      .photo {
        width: 25%;
         @media screen and (max-width: 767px){
           width: 100%;
         }
      }
      .text-area {
        width: 65%;
        padding-top: 0;
        text-align: center;

         @media screen and (max-width: 767px){
           width: 100%;
           text-align: left;
         }

        p {
          word-wrap: break-word;
        }
      }
    }
  }
}
</style>