<template>
  <div class="fl-about-fl">
 <UiHeading
      :h="'2'"
      :type="'D'"
      :text="'カフェメニュー'"
      :subTitle="'Cafe Menu'"
    />

        <UiItemBox
      :type="`B`"
      :imgpath="`yui-top-005.jpeg`"
      :imgName="`ケーキセット`"
      :itemName="`ケーキセット`"
      :price="`お好きなケーキ＋ドリンク`"
      :itemText="`※ミツバチ食堂内でお召し上がりいただけます`"
      :itemComent="``"
    />
    <div class="space-50">
<UiBox
      :type="'B2'"
      :imgpath="'yui-top-006.JPEG'"
      :imgName="'お誕生日'"
      :text="'誕生日のメッセージプレートも対応可能です。ご相談ください。'"
    />
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from '../Ui/Heading.vue';
import UiItemBox from '../Ui/ItemBox.vue';
import UiBox from '../Ui/Box.vue';

export default {
  name: "FlAboutFl",
  components: {   
     UiHeading,
     UiItemBox,
     UiBox,
     },
  props: {
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>

</style>
