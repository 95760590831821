var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-map"},[_c('div',{class:{
      typeA: _vm.type === 'A',
      //左：table
      //右：img
      typeB: _vm.type === 'B',
    }},[_c('div',{staticClass:"cover"},[_c('div',{staticClass:"shop-info"},[_c('table',[_c('tr',[_vm._m(0),_c('td',{domProps:{"innerHTML":_vm._s(_vm.name)}})]),_c('tr',[_vm._m(1),_c('td',{domProps:{"innerHTML":_vm._s(_vm.address)}})]),_c('tr',[_vm._m(2),_c('td',[_c('a',{attrs:{"href":"tel:058-266-5004"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.tel)}})])])]),_c('tr',[_vm._m(3),_c('td',{domProps:{"innerHTML":_vm._s(_vm.open)}})]),_c('tr',[_vm._m(4),_c('td',{domProps:{"innerHTML":_vm._s(_vm.close)}})]),_c('tr',[_vm._m(5),_c('td',[_c('a',{attrs:{"href":_vm.insta,"target":"_blank","rel":"noopener noreferrer"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.insta)}})])])])])]),_c('img',{staticClass:"photo",attrs:{"src":`${_vm.rootUrl}/img/${_vm.imgpath}`,"alt":{ imgName: _vm.imgName }}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("店舗名")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("住所")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("電話番号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("営業時間")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("定休日")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("instagram")])])
}]

export { render, staticRenderFns }