<template>
  <div class="ui-drawer">
    <div
      class="back-gray"
      :class="{ 'back-gray-show': openDrawer === true }"
      @click="switchDrawer"
    ></div>
    <div @click="switchDrawer" class="drawer-button" alt="ドロワーメニュー">
      <svg
        fill="#c1272d"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="40px"
        height="50px"
      >
        <path
          d="M 0 9 L 0 11 L 50 11 L 50 9 Z M 0 24 L 0 26 L 50 26 L 50 24 Z M 0 39 L 0 41 L 50 41 L 50 39 Z"
        />
      </svg>
    </div>
    
    <div
      class="drawer-menu"
      :class="{
        'open-drawer': openDrawer === true,
        'close-drawer': openDrawer === false,
      }"
    >
      <div class="drawer-header">
        <div
          @click="switchDrawer"
          class="close-button"
          alt="閉じるドロワーメニュー"
        >
          <svg
            fill="#c1272d"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="30px"
            height="50px"
          >
            <path
              d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"
            />
          </svg>
        </div>
      </div>
      <div class="drawer-body" @click="switchDrawer">
        <!-- <div class="drawer-item">
          <router-link to="/">トップ</router-link>
        </div> -->
        <div class="drawer-item" v-scroll-to="'#about'">
                    <!-- <router-link to="/">トップ</router-link> -->
        <span>低糖質専門店 結について</span>
        </div>
        <div class="drawer-item" v-scroll-to="'#mission'">
          <!-- <router-link to="/zeimukomon/">税務顧問・記帳代行</router-link> -->
        <span>結の思い</span>
        </div>
        <div class="drawer-item" v-scroll-to="'#commitment'">
          <!-- <router-link to="/zeimukomon/">税務顧問・記帳代行</router-link> -->
        <span>こだわりのチーズケーキ</span>
        </div>
        <div class="drawer-item" v-scroll-to="'#cafemenu'">
          <!-- <router-link to="/zeimukomon/">税務顧問・記帳代行</router-link> -->
         <span>カフェメニュー</span>
        </div>
        <div class="drawer-item" v-scroll-to="'#info'">
          <!-- <router-link to="/zeimukomon/">税務顧問・記帳代行</router-link> -->
          <span>ショップ情報</span>
        </div>
     
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiDrawer",
  data() {
    return {
      openDrawer: false,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {
    switchDrawer() {
      this.openDrawer = this.openDrawer === false ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
$width: 230px;
.drawer-button {
  width: 38px;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    width: 40px;
  }
}
.drawer-button:hover{
opacity: 0.8;
}
.drawer-menu {
  transition: all 1s 0s ease;
  position: fixed;
  top: 0;
  background-color: white;
  padding: 5px 10px 50px 10px;
  box-sizing: border-box;
  z-index: 999;
  width: $width;
  .drawer-header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    img.close-button {
      width: 20px;
      margin: 20px;
      cursor: pointer;
    }
  }
  .drawer-body {
    // background-color: var(--beige);
    .drawer-item {
      text-align: initial;
      border-top: 1px solid var(--main);

      padding: 18px 10px;

      &:last-child {
        border-bottom: 1px solid var(--main);
      }
      a,span {
        text-decoration: none;
        color: var(--black);
            &:hover {
          // background-color: var(--beige);
          // font-weight: bold;
          color: var(--main);
          cursor: pointer;
        }
      }
    }
  }
}
.open-drawer {
  transform: translateX(-$width);
  // right: translateX(-$width);
  right: -$width;
}
.close-drawer {
  transform: translateX($width);
  right: -$width;
  // right: 0-$width;
}

.back-gray {
  display: none;
  position: fixed;
  z-index: 995;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.4;
}
.back-gray-show {
  display: initial;
}
</style>
