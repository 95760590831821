<template>
  <div class="home-map">
 <UiHeading
      :h="'2'"
      :type="'D'"
      :text="'ショップ情報'"
      :subTitle="'information'"
    />
    <h3 class="shopTitle"><span class="underLine">オンラインショップ</span></h3>
    <UiButton />
    <h3 class="shopTitle"><span class="underLine">店舗</span></h3>
    <p class="shopDesc">結の低糖質ケーキ、生菓子、焼菓子、パンなどはミツバチ食堂の店内でも購入できます。</p>
    <p class="shopDesc">※店内でのお召し上がりもテイクアウトもできます</p>
    <UiMap
    :type="`A`"
    :imgpath="'yui-map.jpg'" :imgName="'yui-imge'" :name="`ミツバチ食堂`" :address="`岐阜市日ノ出町2-5`" :tel="`058-266-5004`" :open="`ランチ：11:00-15:00（LO14:30）<br/>カフェタイム：14:30-18:00<br/>ディナー：18:00-21:00（LO20:15）`" :close="`月・火（祝日営業）`"
    />
          <div class="space-50">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3251.4560553980727!2d136.7558996122374!3d35.41873107255909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003a94593fdd7a5%3A0xe0b6f1e1f9179264!2z44Of44OE44OQ44OB6aOf5aCC!5e0!3m2!1sja!2sjp!4v1724296196940!5m2!1sja!2sjp" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiMap from '../Ui/Map.vue';
import UiHeading from '../Ui/Heading.vue';
import UiButton from "@/components/Ui/Button.vue";

export default {
  name: "HomeMap",
  components: {   
    UiMap,
    UiHeading,
    UiButton
    },
  props: {
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>

h1,h2 {
  font-size: 32px;
}
h3{
  font-size: 22px;
}


.typeA {
color: var(--green);

  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
  }
}
.typeB,.typeB2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: white;
  }
}
.typeB{
  background-color: var(--green);
}.typeB2{
  background-color: var(--blue);
}
.typeC {
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: left;
    border-bottom: var(--green) solid 1px;
    padding-bottom: 10px;
    font-weight: 500;
  }
}
.shopTitle {
  text-align: center;
  margin: 50px 0;
}

.underLine {
  padding-bottom: 2px;
  border-bottom: 1px solid var(--sub);
}
.shopDesc {
  text-align: center;
}
</style>
