<template>
  <div class="heading">
    <div
      :class="{
        typeA: type === 'A',
        typeB: type === 'B',
        typeB2: type === 'B2',
        typeB3: type === 'B3',
        typeC: type === 'C',
        typeD: type === 'D',
        typeD2: type === 'D2',
      }"
    >
      <h1 v-if="h === '1'" v-html="text"></h1>
      <h2 v-else-if="h === '2'" v-html="text"></h2>
      <h3 v-else-if="h === '3'" v-html="text"></h3>
      <h4 v-else-if="h === '4'" v-html="text"></h4>
      <h5 v-else-if="h === '5'" v-html="text"></h5>
    </div>
        <div v-if="subTitle !==''" class="sub">
      <p>
      {{subTitle}}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "heading",
  components: {},
  props: {
    text: String,
    h: String,
    type: String,
    subTitle:String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>

h1,h2 {
  font-size: 32px;
   @media screen and (max-width: 767px){
     font-size: 28px;
     padding: 0 10px;
     line-height: 1.5em!important;
   }
}
h3{
  font-size: 22px;
  line-height: 1.5em!important;
   @media screen and (max-width: 767px){
     font-size: 20px;
   }
}


.typeA {
// color: var(--main);

  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
  }
}
.typeB,.typeB2,.typeB3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: white;
  }
}
.typeB,.typeB3{//背景ブルー
  background-color: var(--main);
}.typeB2{//背景グリーン
  background-color: var(--sub);
}
.typeB3{//SP：3行用
   @media screen and (max-width: 767px){
     h2{
       font-size: 26px;
     }
   }
}
.typeC {
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: left;
    border-bottom: var(--main) solid 1px;
    padding-bottom: 10px;
    font-weight: 500;
  }
}
.typeD,.typeD2{
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
    padding-bottom: 10px;
  }
}
.typeD{
  // color: var(--sub);
}
.typeD2{
  color: var(--main);
  line-height: 3em!important;
}
.sub{
  color: var(--main);
  text-align: center;
  padding-top: 5px;
  font-size: 18px;
}
</style>
