var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-button",attrs:{"ontouchstart":""}},[_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
      el: '#app',
      container: 'body',
      duration: 750,
      easing: 'ease',
      offset: 0,
    }),expression:"{\n      el: '#app',\n      container: 'body',\n      duration: 750,\n      easing: 'ease',\n      offset: 0,\n    }"}],staticClass:"to-top-button",class:{'over-baseline': _vm.scrollY >= 500 }},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":`${_vm.rootUrl}/img/modoruA002.png`,"alt":"トップに戻る"}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }