<template>
  <div class="home-mission">
    <UiHeading
      :h="'2'"
      :type="'D'"
      :text="'結の思い'"
      :subTitle="'our mission'"
    />
    <h3>体のことを気にされた方でも食べられるスイーツで広がる幸せ</h3>

    <div class="mission-text">
      <p>
        「美味しいものは糖と脂肪でできている。」という言葉があるように、<br />
        スイーツは糖か脂肪のどちらかが欠けると、おいしいと感じる味にならないだけでなく、形にすらなりません。<br />
        そのため、お体のことを気にされている方だと、食べたくても食べられないのがスイーツです。<br />
      </p>
      <p>
        当店の店主の私も、幼いころから、目の前のこのような経験をしてきました。<br />
        私は家族に糖尿病の患者がいたため、外食時やお祝いの席の時に、家族みんなで同じ食べ物を共有できないことに悲しさをおぼえました。
      </p>
      <p>
        母親が看護師だったこともあり、病気の人がいる家庭では、<br />
        「食べたくても食べられない人がいる」<br />
        「家庭では食事を制限するのに限度がある」<br />
        そういった声がたくさんあることを知りました。
      </p>
      <p>
        このような自分の経験があったからこそ、<br />
        糖質制限をしている方やその周りの方も難しく考えず、<br />
        同じものを同じように共有し、喜びを分かち合える。<br />
        そんなお菓子、ご飯を作りたい、届けたいと思うようになりました。
      </p>
      <p>
        このような思いから、当店では、調理法や材料にこだわり、最大約93％の糖質カットのケーキを作っています。<br />
        材料には、主におからパウダーやふすま粉、ラカント（天然甘味料）を使用することで糖質を大きくカットしました。
      </p>
      <p>
        病気の方、体に気を遣われている方、身体づくりしている方、ダイエット中の方、年配の方、健康体の方、みんなで同じものを囲って同じものを安心してたくさん食べていただけますように。<br />
        こんな想いで作らせていただいております。
      </p>
      <p>結のお菓子と共に素敵な時間をお過ごしくださいませ。</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";

export default {
  name: "HomeMission",
  components: {
    UiHeading,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
h3{
  padding-top: 80px;
  padding-bottom: 50px;
  font-size: 26px;
@media screen and (max-width:767px){
  line-height: 1.5em;
  padding-top: 50px;
padding-bottom: 30px;
}
}
.mission-text{
  p{
    padding: 30px 0;
    color: #333333;
  }
}
</style>
