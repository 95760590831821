<template>
  <div class="ui-item-box">
    <div
      :class="{
        typeA: type === 'A',
        //左：table
        //右：img
        typeB: type === 'B',
        //右：
        //右：img
      }"
    >
      <div class="item-cover">
        <img
          :src="`${rootUrl}/img/${imgpath}`"
          :alt="{ imgName }"
          class="photo"
        />
        <div class="right-cover" v-if="type === 'A'">
          <div class="shop-info">
            <table>
              <tr>
                <th><span>商品名</span></th>
                <td v-html="itemName"></td>
              </tr>
              <tr>
                <th><span>価格</span></th>
                <td v-html="price"></td>
              </tr>
              <tr>
                <th><span>送料と配送</span></th>
                <td v-html="delivery"></td>
              </tr>
            </table>
          </div>
          <div class="text-box">
            <p v-html="itemText"></p>
          </div>
        </div>
        <div class="right-cover" v-else-if="type === 'B'">
          <div class="shop-info">
            <p class="name" v-html="itemName"></p>
            <p  class="price" v-html="price"></p>
            <p class="kome" v-html="itemText"></p>
            <p class="coment" v-html="itemComent"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiMap",
  components: {},
  props: {
    type: String,
    imgpath: String,
    imgName: String,
    itemName: String,
    price: String,
    delivery: String,
    itemText: String,
    kome: String,
    itemComent: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.ui-item-box {
  .typeA,
  .typeB {
    padding-top: 48px;
    .item-cover {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .photo {
        width: 48%;
        @media screen and (max-width:767px){
          width: 100%;
        }
      }
    }
  }
  .typeA {
    .right-cover {
      width: 45%;
      display: flex;
      flex-direction: column;
              @media screen and (max-width:767px){
          width: 100%;
        }
      .text-box {
        // width: 50%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      .shop-info {
        flex-basis: 30%;
        table {
          width: 100%;
          margin: 0px auto 35px auto;
          // border: 1px solid var(--main);
          tr {
            th,
            td {
              padding: 15px 5px;
            }
            th {
              text-align: left;
              white-space: nowrap;
              width: 130px;
              span {
                padding-bottom: 2px;
                border-bottom: 1px solid var(--sub);
              }
            }
          }
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
  .typeB {
    .right-cover {
      width: 48%;
      height: auto;
        display: flex;
        justify-content: center;
                @media screen and (max-width:767px){
          width: 100%;
        }
      .shop-info {
        // flex-direction: column;
        // justify-content: center
        p,.price {
          // padding-bottom: 13%;
          line-height: 1.5em;
        /deep/.zei{
            font-size: 12px!important;
          }
        }
        .name{
          font-size: 32px;
          padding: 10px 0;
          margin-bottom: 20px;
          border-bottom: solid var(--main) 1px;
                  @media screen and (max-width:767px){
       padding-top: 30px;
       }
        }
        .price{
          font-size: 22px;
          margin-bottom: 20px;
              @media screen and (max-width:767px){
    line-height: 1.5em;
    }
        }
        .coment{
          margin-top: 50px;
          font-size: 18px;
        }
      }
      .text-box {
      margin-top: 14%;
      }
    }
  }
  .kome {
    @media screen and (max-width: 767px) {
      font-size: 15px;
    }
  }
}
</style>