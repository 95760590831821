<template>
  <div id="app">
    <Header />
    <router-view class="body-top" />
    <ToTopButton :path="path" />
    <Footer />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import "reset-css";
import Header from "@/components/Layout/Header.vue";
import ToTopButton from "@/components/Ui/ToTopButton.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
    name: "App",
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
    ToTopButton,
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;700&display=swap');

#app {
  font-family: "Shippori Mincho","游明朝", "Yu Mincho","	YuMincho","游明朝体",
    serif;
  font-size: 16px;
  color: #606060;
  line-height: 1.5em
}
/* 共通設定 */
html * {
  box-sizing: border-box !important;
}
:root {
  --main: #c1272d;
  --sub: #cccccc;

}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

.wrap {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1100px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  margin: 0 auto;
  line-height: 1.5em;

  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.sp-kaigyo {
  display: none;

  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}

.pc-kaigyo {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.body-top {
  padding-top: 70px;
}
h1,h2{
  font-size: 26px;
  @media screen and (max-width: 768px) {
    line-height: 1.5em;
  }
}
h3{
  font-size: 20px;
    @media screen and (max-width: 768px) {
    line-height: 1.5em;
  }
}

.pc {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.sp {
  display: none;
  @media screen and (max-width: 767px) {
    display: initial;
  }
}

.space-50 {
  margin: 50px 0;
  @media screen and (max-width: 767px) {
    margin: 25px 0;
  }
}
.space-100 {
  margin-top: 100px;
  @media screen and (max-width: 767px) {
    margin-top: 50px;
  }
}
.space-100p {
  padding-top: 100px;
  @media screen and (max-width: 767px) {
    padding-top: 50px;
  }
}
.space-150 {
  margin-top: 150px;
  @media screen and (max-width: 767px) {
    margin-top: 75px;
  }
}
.space-150p {
  padding-top: 150px;
  @media screen and (max-width: 767px) {
    padding-top: 75px;
  }
}
.space-200 {
  margin-top: 200px;
  @media screen and (max-width: 767px) {
    margin-top: 100px;
  }
}
.space-200p {
  padding-top: 200px;
  @media screen and (max-width: 767px) {
    padding-top: 100px;
  }
}


</style>
